import React from 'react';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';
import Preorders from './Preorders';
import { useEffect } from 'react';
import { getAllPreorders } from '../../service/OrdersService';
import { useState } from 'react';

const PreorderMain = ({ user }) => {
  const [search, setSearch] = useState('');
  const [preorders, setPreorders] = useState();
  const [preordersFiltered, setPreordersFiltered] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (user) {
      getAllPreorders()
        .then((preorders) => {
          const inverseArray = [];
          preorders.map((preorder) => inverseArray.unshift(preorder));
          setPreorders(inverseArray);
          setLoading(false);
        })
        .catch((e) => setError);
    }
  }, [user]);

  useEffect(() => {
    if (preorders) {
      setPreordersFiltered(
        preorders.filter((preorder) => preorder.user.email.includes(search))
      );
    }
  }, [preorders, search]);

  return (
    <section className='content-main'>
      <div className='content-header'>
        <h2 className='content-title'>Pedidos sin completar</h2>
      </div>

      <div className='card mb-4 shadow-sm'>
        <header className='card-header bg-white'>
          <div className='row gx-3 py-3'>
            <div className='col-6 mx-auto'>
              <input
                type='text'
                placeholder='Buscar...'
                className='form-control p-2'
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {/* <div className='col-lg-3 col-6 col-md-3'>
              <select className='form-select'>
                <option>Status</option>
                <option>Active</option>
                <option>Disabled</option>
                <option>Show all</option>
              </select>
            </div> */}
            {/* <div className='col-lg-2 col-6 col-md-3'>
              <select className='form-select'>
                <option>Show 20</option>
                <option>Show 30</option>
                <option>Show 40</option>
              </select>
            </div> */}
          </div>
        </header>
        <div className='card-body'>
          <div className='table-responsive'>
            {loading ? (
              <Loading />
            ) : error ? (
              <Message variant='alert-danger'>{error}</Message>
            ) : (
              <Preorders preorders={preordersFiltered} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreorderMain;
