import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Product from './Product';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';
import { listProducts } from '../../service/ProductsService';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';

const MainProducts = () => {
  const [products, setProducts] = useState();
  const [productsFiltered, setProductsFiltered] = useState();
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorDelete] = useState();

  const { is775 } = useContext(ResponsiveContext);

  useEffect(() => {
    listProducts()
      .then((products) => {
        setProducts(products);
        setLoading(false);
      })
      .catch((error) => setError(error.response.data.message));
  }, []);

  useEffect(() => {
    if (products) {
      setProductsFiltered(products);
    }
  }, [products]);

  const handleSearch = (input) => {
    setSearch(input);
    const filtered = products.filter((products) =>
      products.name.toLowerCase().includes(input.toLowerCase())
    );

    setProductsFiltered(filtered);
  };

  // TODO
  // pagination

  return (
    <section className='content-main'>
      <div className='content-header d-flex flex-row justify-content-between'>
        {is775 ? (
          <>
            <div></div>
            <h2 className='content-title'>Productos</h2>
            <div>
              <Link to='/addproduct' className='btn btn-primary'>
                Create producto
              </Link>
            </div>
          </>
        ) : (
          <>
            <h2 className='content-title'>Productos</h2>
            <div>
              <Link to='/addproduct' className='btn btn-primary'>
                Create producto
              </Link>
            </div>
          </>
        )}
      </div>

      <div className='card mb-4 shadow-sm'>
        <header className='card-header bg-white '>
          <div className='row gx-3 py-3'>
            <div className='col-12 mx-auto '>
              <input
                type='search'
                placeholder='Buscar...'
                className='form-control p-2'
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            {/* <div className='col-lg-2 col-6 col-md-3'>
              <select className='form-select'>
                <option>All category</option>
                <option>Electronics</option>
                <option>Clothings</option>
                <option>Something else</option>
              </select>
            </div>
            <div className='col-lg-2 col-6 col-md-3'>
              <select className='form-select'>
                <option>Latest added</option>
                <option>Cheap first</option>
                <option>Most viewed</option>
              </select>
            </div> */}
          </div>
        </header>

        <div className='card-body'>
          {errorDelete && (
            <Message variant='alert-danger'>{errorDelete}</Message>
          )}
          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant='alert-danger'>{error}</Message>
          ) : (
            <div className='row'>
              {/* Products */}
              {productsFiltered?.map((product) => (
                <Product product={product} key={product._id} />
              ))}
            </div>
          )}

          {/* <nav className='float-end mt-4' aria-label='Page navigation'>
            <ul className='pagination'>
              <li className='page-item disabled'>
                <Link className='page-link' to='#'>
                  Previous
                </Link>
              </li>
              <li className='page-item active'>
                <Link className='page-link' to='#'>
                  1
                </Link>
              </li>
              <li className='page-item'>
                <Link className='page-link' to='#'>
                  2
                </Link>
              </li>
              <li className='page-item'>
                <Link className='page-link' to='#'>
                  3
                </Link>
              </li>
              <li className='page-item'>
                <Link className='page-link' to='#'>
                  Next
                </Link>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
    </section>
  );
};

export default MainProducts;
