import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Header from '../components/Header';
import PreorderDetailmain from '../components/preorders/PreorderDetailmain';

const PreorderDetailScreen = ({ user }) => {
  const navigate = useNavigate();

  const params = useParams();
  const preorderId = params.id;

  useEffect(() => {
    if (!user || (user && !user.isAdmin)) {
      navigate('/login');
    }
  });

  return (
    <>
      <Sidebar />
      <main className='main-wrap'>
        <Header />
        <PreorderDetailmain preorderId={preorderId} />
      </main>
    </>
  );
};

export default PreorderDetailScreen;
