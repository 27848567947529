import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/LoadingError/Loading';
import Toast from '../components/LoadingError/Toast';
import { useAuth } from '../hooks/useAuth';
import Message from './../components/LoadingError/Error';

const Login = ({ user }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { login } = useAuth();
  // const { login, loginGoogle } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  const submitHandler = (e) => {
    e.preventDefault();
    const credentials = {
      email: email,
      password: password,
    };
    setLoading(true);
    login(credentials)
      .then((user) => {
        setLoading(false);
        navigate('/');
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  return (
    <>
      <Toast />
      <div
        className='card shadow mx-auto'
        style={{ maxWidth: '380px', marginTop: '100px' }}
      >
        <div className='card-body'>
          <h4 className='card-title mb-4 text-center'>Acceso</h4>

          <form onSubmit={submitHandler}>
            {loading ? (
              <div className='my-5'>
                <Loading />
              </div>
            ) : (
              <>
                <div className='mb-3'>
                  <input
                    className='form-control'
                    placeholder='Email'
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className='mb-3'>
                  <input
                    className='form-control'
                    placeholder='Password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </>
            )}

            <div className='mb-4'>
              <button type='submit' className='btn btn-primary w-100'>
                Acceder
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        style={{
          marginTop: '24px',
        }}
      >
        {error && <Message variant='alert-danger'>{error}</Message>}
      </div>
    </>
  );
};

export default Login;
