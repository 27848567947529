import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Header from '../components/Header';
import OrderDetailmain from '../components/orders/OrderDetailmain';

const OrderDetailScreen = ({ user }) => {
  const navigate = useNavigate();

  const params = useParams();
  const orderId = params.id;

  useEffect(() => {
    if (!user || (user && !user.isAdmin)) {
      navigate('/login');
    }
  });

  return (
    <>
      <Sidebar />
      <main className='main-wrap'>
        <Header />
        <OrderDetailmain orderId={orderId} user={user} />
      </main>
    </>
  );
};

export default OrderDetailScreen;
