import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Main from '../components/Home/Main';
import Message from '../components/LoadingError/Error';
import { getAllOrders } from '../service/OrdersService';
import { listProducts } from '../service/ProductsService';
import Sidebar from './../components/sidebar';

const HomeScreen = ({ user }) => {
  const [orders, setOrders] = useState();
  const [products, setProducts] = useState();
  const [loadingOrders, setLoadingOrders] = useState(true);
  // const [loadingProducts, setLoadingProducts] = useState(true);
  const [errorOrders, setErrorOrders] = useState();
  const [errorProducts, setErrorProducts] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || (user && !user.isAdmin)) {
      navigate('/login');
    }
  }, [user]);

  useEffect(() => {
    if (user && user.isAdmin) {
      getAllOrders()
        .then((orders) => {
          setOrders(orders);
          setLoadingOrders(false);
        })
        .catch((error) => setErrorOrders(error.response.data.message));

      listProducts()
        .then((products) => {
          setProducts(products);
          // setLoadingProducts(false);
        })
        .catch((error) => setErrorProducts(error.response.data.message));
    }
  }, [user]);

  return (
    <>
      <Sidebar />
      <main className='main-wrap'>
        <Header />
        {errorOrders && <Message variant='alert-danger'>{errorOrders}</Message>}
        {errorProducts && (
          <Message variant='alert-danger'>{errorProducts}</Message>
        )}
        <Main
          products={products}
          orders={orders}
          loadingOrders={loadingOrders}
          errorOrders={errorOrders}
        />
      </main>
    </>
  );
};

export default HomeScreen;
