import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './../components/sidebar';
import Header from './../components/Header';
import AddProductMain from './../components/products/AddProductMain';

const AddProduct = ({ user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || (user && !user.isAdmin)) {
      navigate('/login');
    }
  });

  return (
    <>
      <Sidebar />
      <main className='main-wrap'>
        <Header />
        <AddProductMain />
      </main>
    </>
  );
};

export default AddProduct;
