import React, { useEffect, useState } from 'react';
import OrderDetailProducts from './OrderDetailProducts';
import OrderDetailInfo from './OrderDetailInfo';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';
import moment from 'moment';
import 'moment/locale/es';

import {
  findOrder,
  updateOrderStatus,
  deleteOrder,
} from '../../service/OrdersService';

const OrderDetailmain = ({ orderId, user }) => {
  const [status, setStatus] = useState();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingDelivered] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();

  moment.locale('es');

  useEffect(() => {
    if (orderId) {
      findOrder(orderId)
        .then((order) => {
          setOrder(order[0]);
          setStatus(order[0].orderStatus);
          setLoading(false);
        })
        .catch((e) => setError(e));
    }
  }, [orderId]);

  const handleStatus = (target) => {
    setStatus(target);
  };

  const handleUpdate = () => {
    setLoading(true);

    updateOrderStatus(order._id, status).then(() => {
      window.location.reload();
      setLoading(false);
    });
  };

  const deletehandler = (id) => {
    if (window.confirm('¿Estás seguro/a?')) {
      setLoading(true);
      deleteOrder(id)
        .then(() => {
          navigate('/');
        })
        .catch((error) => setError(error.response.data.message));
    }
  };

  return (
    <section className='content-main'>
      <div className='content-header d-flex flex-row justify-content-between'>
        <Link to='/orders' className='btn btn-dark text-white'>
          Volver a los pedidos
        </Link>
        {user?.isSuperAdmin && (
          <button
            type='submit'
            className='btn btn-danger'
            onClick={() => deletehandler(order._id)}
          >
            Eliminar pedido
          </button>
        )}
      </div>

      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant='alert-danger'>{error}</Message>
      ) : (
        <div className='card'>
          <header className='card-header p-3 Header-green'>
            <div className='row align-items-center '>
              <div className='col-lg-6 col-md-6'>
                <span>
                  <i className='far fa-calendar-alt mx-2'></i>
                  <b className='text-white'>
                    {moment(order.createdAt).format('llll')}
                  </b>
                </span>
                <br />
                <small className='text-white mx-3 '>
                  ID del Pedido: {order._id}
                </small>
              </div>
              <div className='col-lg-6 col-md-6 ms-auto d-flex justify-content-end align-items-center'>
                <select
                  className='form-select d-inline-block'
                  style={{ maxWidth: '300px' }}
                  onChange={(e) => handleStatus(e.target.value)}
                >
                  <option
                    selected={order.orderStatus === 'awaitingPayment'}
                    value='awaitingPayment'
                  >
                    Esperando el pago
                  </option>
                  <option
                    selected={order.orderStatus === 'paymentConfirmed'}
                    value='paymentConfirmed'
                  >
                    Pago confirmado
                  </option>
                  <option
                    selected={order.orderStatus === 'shipped'}
                    value='shipped'
                  >
                    Enviado
                  </option>
                  <option
                    selected={order.orderStatus === 'delivered'}
                    value='delivered'
                  >
                    Entregado
                  </option>
                </select>
                {/* <Link className='btn btn-success ms-2' to='#'>
                  <i className='fas fa-print'></i>
                </Link> */}
              </div>
            </div>
          </header>
          <div className='card-body'>
            {/* Order info */}
            <OrderDetailInfo order={order} />

            <div className='row'>
              <div className='col-12'>
                <div className='table-responsive'>
                  <OrderDetailProducts order={order} loading={loading} />
                </div>
              </div>
              {/* Payment Info */}
              <div className='col-12'>
                <div className='box shadow-sm bg-light'>
                  {order.orderStatus === 'delivered' &&
                  status === 'delivered' ? (
                    <button disabled className='btn btn-success col-12'>
                      ENTREGADO EL ({' '}
                      {moment(order.isDeliveredAt).format('MMM Do YYYY')})
                    </button>
                  ) : (
                    <>
                      {loadingDelivered && <Loading />}
                      <button
                        onClick={handleUpdate}
                        className='btn btn-dark col-12'
                      >
                        ACTUALIZAR
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default OrderDetailmain;
