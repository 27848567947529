import { create } from './BaseService';

const http = create();

// Products

export function listProducts() {
  return http.get('/products');
}

export function findProduct(id) {
  return http.get(`/products/${id}`);
}

export function findMyProducts() {
  return http.get('/my-products');
}

export function createProduct(product) {
  return http.post('/new-product', { product });
}

export function updateProduct(id, product) {
  return http.post('/update-product', { id, product });
}

export function deleteProduct(id) {
  return http.post('/delete-product', { id });
}

export function findImages() {
  return http.get('/images');
}

export function deleteImage(id) {
  return http.post('/delete-image', { id });
}

// Like

// export function findLike(product) {
//   return http.get(`/like/${product}`);
// }

// export function findAllLikes() {
//   return http.get('/all-likes');
// }

// export function likeProduct(
//   product,
//   description,
//   category,
//   price,
//   ean,
//   internalCode
// ) {
//   return http.post('/like', {
//     product,
//     description,
//     category,
//     price,
//     ean,
//     internalCode,
//   });
// }

// List

// export function findList(product) {
//   return http.get(`/list/${product}`);
// }

// export function findAllList() {
//   return http.get('/all-lists');
// }

// export function listProduct(
//   product,
//   description,
//   category,
//   price,
//   ean,
//   internalCode
// ) {
//   return http.post('/list', {
//     product,
//     description,
//     category,
//     price,
//     ean,
//     internalCode,
//   });
// }

// Cart

export function findCart(product) {
  return http.get(`/cart/${product}`);
}

export function findAllCarts() {
  return http.get('/all-carts');
}

export function cartProduct({ product, color, size, qty }) {
  return http.post('/cart', {
    product,
    color,
    size,
    qty,
  });
}

export function removeCartProduct({ productId, color, size }) {
  return http.post('/remove-product', {
    productId,
    color,
    size,
  });
}

export function updateCart(products) {
  return http.post('/update-cart', { products });
}

// Review

export function createReview(review) {
  return http.post('/new-review', { review });
}

export function deleteReview(product) {
  return http.post('/delete-review', { product });
}
