import React from 'react';
import { Link } from 'react-router-dom';

const OrderDetailProducts = ({ order, loading }) => {
  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }

  return (
    <table className='table border table-lg'>
      <thead>
        <tr>
          <th className='text-center' style={{ width: '40%' }}>
            Producto
          </th>
          <th className='text-center' style={{ width: '15%' }}>
            Precio
          </th>
          <th className='text-center' style={{ width: '10%' }}>
            Cantidad
          </th>
          <th className='text-center' style={{ width: '15%' }}>
            Color
          </th>
          <th className='text-center' style={{ width: '10%' }}>
            Talla
          </th>
          <th className='text-end' style={{ width: '15%' }}>
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {order.orderItems.map((item, index) => (
          <tr key={index}>
            <td>
              <Link className='itemside' to='#'>
                <div className='left'>
                  <img
                    src={item.image}
                    alt={item.name}
                    style={{ width: '40px', height: '40px' }}
                    className='img-xs'
                  />
                </div>
                <div className='info'>{item.name}</div>
              </Link>
            </td>
            <td className='text-center'>
              {item.price.toString().replace('.', ',')}
              {item.price.toString().replace('.', ',').split(',')[1]
                ? item.price.toString().replace('.', ',').split(',')[1]
                    .length === 1
                  ? '0'
                  : ''
                : ',00'}{' '}
              €
            </td>
            <td className='text-center'>{item.qty} </td>
            <td className='text-center'>{item.color} </td>
            <td className='text-center'>{item.size} </td>
            <td className='text-end'>
              {(item.qty * item.price).toString().replace('.', ',')}
              {(item.qty * item.price)
                .toString()
                .replace('.', ',')
                .split(',')[1]
                ? (item.qty * item.price)
                    .toString()
                    .replace('.', ',')
                    .split(',')[1].length === 1
                  ? '0'
                  : ''
                : ',00'}{' '}
              €
            </td>
          </tr>
        ))}

        <tr>
          <td colSpan='6'>
            <article className='float-end'>
              <dl className='dlist'>
                <dt>Subtotal:</dt>{' '}
                <dd>
                  {(order.itemsPrice / 1.21)
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')}
                  {(order.itemsPrice / 1.21)
                    .toString()
                    .replace('.', ',')
                    .split(',')[1]
                    ? (order.itemsPrice / 1.21)
                        .toString()
                        .replace('.', ',')
                        .split(',')[1].length === 1
                      ? '0'
                      : ''
                    : ',00'}{' '}
                  €
                </dd>
              </dl>
              <dl className='dlist'>
                <dt>Gastos de envío:</dt>{' '}
                <dd>
                  {order.shippingPrice.toString().replace('.', ',')}
                  {order.shippingPrice
                    .toString()
                    .replace('.', ',')
                    .split(',')[1]
                    ? order.shippingPrice
                        .toString()
                        .replace('.', ',')
                        .split(',')[1].length === 1
                      ? '0'
                      : ''
                    : ',00'}{' '}
                  €
                </dd>
              </dl>
              <dl className='dlist'>
                <dt>IVA:</dt>{' '}
                <dd>
                  {order.taxPrice.toString().replace('.', ',')}
                  {order.taxPrice.toString().replace('.', ',').split(',')[1]
                    ? order.taxPrice.toString().replace('.', ',').split(',')[1]
                        .length === 1
                      ? '0'
                      : ''
                    : ',00'}{' '}
                  €
                </dd>
              </dl>
              <dl className='dlist'>
                <dt>Total:</dt>
                <dd>
                  <b className='h6'>
                    {order.totalPrice.toString().replace('.', ',')}
                    {order.totalPrice.toString().replace('.', ',').split(',')[1]
                      ? order.totalPrice
                          .toString()
                          .replace('.', ',')
                          .split(',')[1].length === 1
                        ? '0'
                        : ''
                      : ',00'}{' '}
                    €
                  </b>
                </dd>
              </dl>
              <dl className='dlist mt-4'>
                <dt className='text-muted d-flex align-items-center'>
                  Estado:
                </dt>
                <dd>
                  {order.isPaid ? (
                    <span className='badge rounded-pill alert alert-success text-success'>
                      Pago realizado
                    </span>
                  ) : (
                    <span className='badge rounded-pill alert alert-danger text-danger'>
                      No pagado
                    </span>
                  )}
                </dd>
              </dl>
            </article>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderDetailProducts;
