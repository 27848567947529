import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { ResponsiveContext } from '../contexts/ResponsiveContext';

const Header = () => {
  const { logout } = useAuth();

  const { is775 } = useContext(ResponsiveContext);

  // TODO
  // Search

  return (
    <header className='main-header navbar d-flex flex-row justify-content-between'>
      {/* <div className='col-search'>
        <form className='searchform'>
          <div className='input-group'>
            <input
              list='search_terms'
              type='text'
              className='form-control'
              placeholder='Search term'
            />
            <button className='btn btn-light bg' type='button'>
              <i className='far fa-search' style={{
                marginRight: '15px'
              }}></i>
            </button>
          </div>
          <datalist id='search_terms'>
            <option value='Products' />
            <option value='New orders' />
            <option value='Apple iphone' />
            <option value='Ahmed Hassan' />
          </datalist>
        </form>
      </div> */}
      <div>
        {!is775 && (
          <>
            <button
              className='btn btn-icon btn-aside-minimize '
              type='button'
              id='dropdownMenu2'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <i
                className='text-muted fas fa-stream'
                style={{
                  marginRight: '15px',
                }}
              ></i>
            </button>
            <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
              <li className='menu-item' style={{ height: '50px' }}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'active menu-link' : 'menu-link'
                  }
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    margin: '20px',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  to='/'
                >
                  <i
                    className='icon fas fa-home'
                    style={{
                      marginRight: '15px',
                    }}
                  ></i>
                  <span className='text'>Dashboard</span>
                </NavLink>
              </li>
              <li className='menu-item' style={{ height: '50px' }}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'active menu-link' : 'menu-link'
                  }
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    margin: '20px',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  to='/section'
                >
                  <i
                    className='icon fas fa-shopping-bag'
                    style={{
                      marginRight: '15px',
                    }}
                  ></i>
                  <span className='text'>Sección</span>
                </NavLink>
              </li>
              <li className='menu-item' style={{ height: '50px' }}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'active menu-link' : 'menu-link'
                  }
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    margin: '20px',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  to='/products'
                >
                  <i
                    className='icon fas fa-shopping-bag'
                    style={{
                      marginRight: '15px',
                    }}
                  ></i>
                  <span className='text'>Productos</span>
                </NavLink>
              </li>
              <li className='menu-item' style={{ height: '50px' }}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'active menu-link' : 'menu-link'
                  }
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    margin: '20px',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  to='/addproduct'
                >
                  <i
                    className='icon fas fa-cart-plus'
                    style={{
                      marginRight: '15px',
                    }}
                  ></i>
                  <span className='text'>Añadir producto</span>
                </NavLink>
              </li>
              <li className='menu-item' style={{ height: '50px' }}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'active menu-link' : 'menu-link'
                  }
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    margin: '20px',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  to='/images'
                >
                  <i
                    className='icon fas fa-images'
                    style={{
                      marginRight: '15px',
                    }}
                  ></i>
                  <span className='text'>Imágenes</span>
                </NavLink>
              </li>
              <li className='menu-item' style={{ height: '50px' }}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'active menu-link' : 'menu-link'
                  }
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    margin: '20px',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  to='/orders'
                >
                  <i
                    className='icon fas fa-images'
                    style={{
                      marginRight: '15px',
                    }}
                  ></i>
                  <span className='text'>Pedidos</span>
                </NavLink>
              </li>
              <li className='menu-item' style={{ height: '50px' }}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'active menu-link' : 'menu-link'
                  }
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    margin: '20px',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  to='/preorders'
                >
                  <i
                    className='icon fas fa-images'
                    style={{
                      marginRight: '15px',
                    }}
                  ></i>
                  <span className='text'>Sin completar</span>
                </NavLink>
              </li>
              <li className='menu-item' style={{ height: '50px' }}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? 'active menu-link' : 'menu-link'
                  }
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    margin: '20px',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  to='/users'
                >
                  <i
                    className='icon fas fa-images'
                    style={{
                      marginRight: '15px',
                    }}
                  ></i>
                  <span className='text'>Usuarios</span>
                </NavLink>
              </li>
            </ul>
          </>
        )}
      </div>

      <div>
        <ul className='nav'>
          <li className='dropdown nav-item'>
            <Link className='dropdown-toggle' data-bs-toggle='dropdown' to='#'>
              <img
                className='img-xs rounded-circle'
                src='/images/favicon.png'
                alt='User'
              />
            </Link>
            <div className='dropdown-menu dropdown-menu-end'>
              {/* <Link className='dropdown-item' to='/'>
                My profile
              </Link>
              <Link className='dropdown-item' to='#'>
                Settings
              </Link> */}
              <Link
                onClick={() => logout()}
                className='dropdown-item text-danger'
                style={{ fontSize: '18px' }}
                to='/'
              >
                Salir
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
