import { create } from './BaseService';

const http = create();

export const createUser = (user) => {
  return http.post('/new-user', user);
};

export const updateUser = (user) => {
  return http.post('/update-user', user);
};

export const updateUserSettings = (setting) => {
  return http.post('/update-user/settings', { setting });
};

export const updateUserCards = (cards) => {
  return http.post('/update-user/cards', { cards });
};

export const getCurrentUser = () => {
  return http.get('/users/me');
};

export const getUsers = () => {
  return http.get('/users');
};

export function createSection(data) {
  return http.post('/new-section', { data });
}

export function removeSection() {
  return http.post('/remove-section');
}
