import React from 'react';
import './App.css';
import './responsive.css';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import ImagesScreen from './screens/ImagesScreen';
import OrderScreen from './screens/OrderScreen';
import OrderDetailScreen from './screens/OrderDetailScreen';
import PreorderDetailScreen from './screens/PreorderDetailScreen';
import AddProduct from './screens/AddProduct';
import Login from './screens/LoginScreen';
import UsersScreen from './screens/UsersScreen';
import ProductScreen from './screens/ProductScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import NotFound from './screens/NotFound';
import { useAuth } from './hooks/useAuth';
import PreorderScreen from './screens/PreorderScreen';
import AddSectionHome from './screens/AddSectionHome';

function App() {
  const { user } = useAuth();

  return (
    <>
      <Routes>
        <Route path='/' element={<HomeScreen user={user} />} exact />
        <Route path='/section' element={<AddSectionHome user={user} />} />
        <Route path='/products' element={<ProductScreen user={user} />} />
        <Route path='/images' element={<ImagesScreen user={user} />} />
        <Route path='/orders' element={<OrderScreen user={user} />} />
        <Route path='/preorders' element={<PreorderScreen user={user} />} />
        <Route path='/order/:id' element={<OrderDetailScreen user={user} />} />
        <Route
          path='/preorder/:id'
          element={<PreorderDetailScreen user={user} />}
        />
        <Route path='/addproduct' element={<AddProduct user={user} />} />
        <Route path='/users' element={<UsersScreen user={user} />} />
        <Route
          path='/product/:id/edit'
          element={<ProductEditScreen user={user} />}
        />
        <Route path='/login' element={<Login user={user} />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
