import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Header from '../components/Header';
import OrderMain from '../components/orders/OrderMain';

const OrderScreen = ({ user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || (user && !user.isAdmin)) {
      navigate('/login');
    }
  });

  return (
    <>
      <Sidebar />
      <main className='main-wrap'>
        <Header />
        <OrderMain user={user} />
      </main>
    </>
  );
};

export default OrderScreen;
