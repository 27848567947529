import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { login, loginGoogle } from '../service/AuthService';
import { getCurrentUser } from '../service/UserService';
import {
  deleteAccessToken,
  getAccessToken,
  setAccessToken,
} from '../store/AccessTokenStore';

export const AuthContext = React.createContext({
  user: undefined,
  token: undefined,
});

export default function AuthContextProvider({ children }) {
  const [token, setToken] = useState(getAccessToken());
  const [user, setUser] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      if (!user) {
        getCurrentUser()
          .then((user) => setUser(user))
          .catch(() => {
            deleteAccessToken();
            setToken(undefined);
          });
      }
    } else {
      setUser(undefined);
    }
  }, [token, user]);

  const loginFn = (email, password) => {
    return login(email, password).then((response) => {
      setAccessToken(response.access_token);
      setToken(response.access_token);
    });
  };

  const loginGl = (email, password) => {
    return loginGoogle(email, password)
      .then((response) => {
        setAccessToken(response.access_token);
        setToken(response.access_token);
      })
      .catch((e) => console.log(e));
  };

  const logout = () => {
    deleteAccessToken();
    setToken(undefined);
    setUser(undefined);
    navigate('/');
  };

  // const reload = () => {
  //   getCurrentUser()
  //     .then((user) => setUser(user))
  //     .catch(() => {
  //       deleteAccessToken();
  //       setToken(undefined);
  //     });
  // };

  const value = {
    user: user,
    token: token,
    login: loginFn,
    loginGoogle: loginGl,
    logout,
    // reload,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
