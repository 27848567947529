import axios from "axios";
import { getAccessToken } from "../store/AccessTokenStore";
// import { logout } from "../contexts/AuthContext";   // TODO

export const create = (useAccessToken = true) => {
  const http = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}`, // TODO: use environment variables
  });

  http.interceptors.request.use((request) => {
    if (useAccessToken && getAccessToken()) {
      request.headers.common.Authorization = `Bearer ${getAccessToken()}`;
    }
    return request;
  });

  // http.interceptors.response.use(
  //   (response) => response.data,
  //   (error) => {
  //     if (
  //       reloadOnUnathorized &&
  //       error.response &&
  //       [401, 403].includes(error.response.status)
  //     ) {
  //       logout();
  //     }

  //     return Promise.reject(error);
  //   }
  // );

  http.interceptors.response.use((response) => response.data);

  return http;
};
