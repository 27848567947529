import React from 'react';
import TopTotal from './TopTotal';
import LatestOrder from './LatestOrder';
// import SaleStatistics from './SalesStatistics';
// import ProductsStatistics from './ProductsStatistics';

const Main = ({ products, orders, loadingOrders, errorOrders }) => {
  // TODO
  // Statistics

  return (
    <>
      <section className='content-main'>
        <div className='content-header'>
          <h2 className='content-title text-center' style={{ width: '100%' }}>
            {' '}
            Dashboard{' '}
          </h2>
        </div>
        {/* Top Total */}
        <TopTotal orders={orders} products={products} />

        <div className='row'>
          {/* STATICS */}
          {/* <SaleStatistics />
          <ProductsStatistics /> */}
        </div>

        {/* LATEST ORDER */}
        <div className='card mb-4 shadow-sm'>
          <LatestOrder
            orders={orders}
            loading={loadingOrders}
            error={errorOrders}
          />
        </div>
      </section>
    </>
  );
};

export default Main;
