import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Toast from '../LoadingError/Toast';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { createSection, removeSection } from '../../service/UserService';

const AddProductMain = () => {
  const [title, setTitle] = useState();
  const [image, setImage] = useState();
  const [newImage, setNewImage] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();

  const { is775 } = useContext(ResponsiveContext);

  const handleRemove = () => {
    removeSection()
      .then(() => navigate('/'))
      .catch((e) => setError(e));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (newImage) {
      const formData = new FormData();
      formData.append('file', newImage);

      formData.append('upload_preset', 'deportes-sporting');

      const data = await fetch(
        'https://api.cloudinary.com/v1_1/dlqllsdno/image/upload',
        { method: 'POST', body: formData }
      ).then((r) => r.json());

      setImage(data.secure_url);

      const section = {
        title: title,
        image: data.secure_url,
      };

      createSection(section)
        .then(() => {
          setLoading(false);
          navigate('/products');
        })
        .catch((error) => setError(error.response.data.message));
    } else {
      const section = {
        title: title,
        image: image,
      };

      createSection(section)
        .then(() => {
          setLoading(false);
          navigate('/');
        })
        .catch((error) => setError(error.response.data.message));
    }
  };

  return (
    <>
      <Toast />
      <section className='content-main'>
        <form onSubmit={submitHandler}>
          {is775 ? (
            <div className='content-header'>
              <button
                onClick={handleRemove}
                className='btn btn-danger text-white'
                style={{ height: '100%' }}
              >
                Eliminar sección actual
              </button>
              <h2 className='content-title'>Sección</h2>
              <div>
                <button type='submit' className='btn btn-primary'>
                  Actualizar Sección
                </button>
              </div>
            </div>
          ) : (
            <>
              <div
                className='content-header
                  d-flex flex-row justify-content-between'
              >
                <button
                  onClick={handleRemove}
                  className='btn btn-danger text-white'
                >
                  Eliminar Sección
                </button>
                <button type='submit' className='btn btn-primary'>
                  Actualizar Sección
                </button>
              </div>
              <div className='content-header'>
                <h2
                  className='content-title mt-3'
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  Sección
                </h2>
              </div>
            </>
          )}

          <div className='row mb-4'>
            <div className='col-12'>
              <div className='card mb-4 shadow-sm'>
                <div className='card-body'>
                  {error && <Message variant='alert-danger'>{error}</Message>}
                  {loading && <Loading />}
                  <div className='mb-4'>
                    <label htmlFor='product_title' className='form-label'>
                      Título de la sección
                    </label>
                    <input
                      type='text'
                      placeholder='Escribir aquí'
                      className='form-control'
                      id='product_title'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className='mb-4'>
                    <label className='form-label'>URL Imagen</label>
                    <input
                      className='form-control'
                      type='text'
                      value={image?.split('upload/')[1]}
                      onChange={(e) =>
                        setImage(
                          `https://res.cloudinary.com/dlqllsdno/image/upload/${e.target.value}`
                        )
                      }
                    />
                    <div className='mb-4'>
                      <label className='form-label'>Nueva Imagen</label>
                      <input
                        className='form-control'
                        name='image'
                        id='image'
                        type='file'
                        alt=''
                        onChange={(e) => setNewImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>{' '}
          </div>
        </form>
      </section>
    </>
  );
};

export default AddProductMain;
