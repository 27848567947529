import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteImage } from '../../service/ProductsService';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';

const Image = ({ image }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const deletehandler = (id) => {
    if (window.confirm('¿Estás seguro/a?')) {
      setLoading(true);
      deleteImage(id)
        .then(() => {
          setLoading(false);
          window.location.reload();
        })
        .catch((error) => setError(error.response.data.message));
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant='alert-danger'>{error}</Message>
      ) : (
        <div className='col-md-6 col-sm-6 col-lg-4 mb-5'>
          <div className='card card-image-grid shadow-sm d-flex flex-column align-content-center'>
            <img
              src={image.secure_url}
              alt='library'
              style={{ width: '200px' }}
              className='d-flex m-auto'
            />
            <div className='info-wrap'>
              <span
                className='d-flex justify-content-center mt-3'
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {image.secure_url.split('upload/')[1]}
                {/* {image.secure_url} */}
              </span>
              <Link
                to='#'
                onClick={() => deletehandler(image.public_id)}
                className='btn btn-sm btn-outline-danger mt-3 p-2 pb-3 col-12'
              >
                <i className='fas fa-trash-alt'></i>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Image;
