import React, { useEffect, useState } from 'react';
import Image from './Image';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';
import { findImages } from '../../service/ProductsService';

const MainImages = () => {
  const [images, setImages] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorDelete] = useState();

  useEffect(() => {
    findImages()
      .then((response) => {
        setLoading(false);
        setImages(response);
      })
      .catch((error) => setError(error.response.data.message));
  }, []);

  // TODO
  // pagination

  return (
    <section className='content-main'>
      <div className='content-header'>
        <h2 className='content-title text-center' style={{ width: '100%' }}>
          Imágenes
        </h2>
        {/* <div>
          <Link to='/addproduct' className='btn btn-primary'>
            Create new
          </Link>
        </div> */}
      </div>

      <div className='card mb-4 shadow-sm'>
        {/* <header className='card-header bg-white '>
          <div className='row gx-3 py-3'>
            <div className='col-6 mx-auto '>
              <input
                type='search'
                placeholder='Search...'
                className='form-control p-2'
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className='col-lg-2 col-6 col-md-3'>
              <select className='form-select'>
                <option>All category</option>
                <option>Electronics</option>
                <option>Clothings</option>
                <option>Something else</option>
              </select>
            </div>
            <div className='col-lg-2 col-6 col-md-3'>
              <select className='form-select'>
                <option>Latest added</option>
                <option>Cheap first</option>
                <option>Most viewed</option>
              </select>
            </div>
          </div>
        </header> */}

        <div className='card-body'>
          {errorDelete && (
            <Message variant='alert-danger'>{errorDelete}</Message>
          )}
          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant='alert-danger'>{error}</Message>
          ) : (
            <div className='row'>
              {/* Images */}
              {images?.map((image) => (
                <Image image={image} key={image.asset_id} />
              ))}
            </div>
          )}

          {/* <nav className='float-end mt-4' aria-label='Page navigation'>
            <ul className='pagination'>
              <li className='page-item disabled'>
                <Link className='page-link' to='#'>
                  Previous
                </Link>
              </li>
              <li className='page-item active'>
                <Link className='page-link' to='#'>
                  1
                </Link>
              </li>
              <li className='page-item'>
                <Link className='page-link' to='#'>
                  2
                </Link>
              </li>
              <li className='page-item'>
                <Link className='page-link' to='#'>
                  3
                </Link>
              </li>
              <li className='page-item'>
                <Link className='page-link' to='#'>
                  Next
                </Link>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
    </section>
  );
};

export default MainImages;
