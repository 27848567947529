import React from 'react';

const PreorderDetailInfo = ({ preorder }) => {
  return (
    <div className='row mb-5 preorder-info-wrap'>
      <div className='col-md-6 col-lg-4'>
        <article className='icontext align-items-start'>
          <span className='icon icon-sm rounded-circle alert-success'>
            <i className='text-success fas fa-user'></i>
          </span>
          <div className='text'>
            <h6 className='mb-1'>
              <strong>Cliente</strong>
            </h6>
            <p className='mb-1'>
              {preorder?.user.name ? preorder?.user.name : ''}{' '}
              {preorder?.user.lastName ? preorder?.user.lastName : ''} <br />
              <a href={`mailto:${preorder.user.email}`}>
                {preorder?.user.email ? preorder?.user.email : ''}
              </a>
            </p>
            <p className='mb-1'>
              {preorder?.user.phone ? preorder?.user.phone : ''}
            </p>
          </div>
        </article>
      </div>
      <div className='col-md-6 col-lg-4'>
        <article className='icontext align-items-start'>
          <span className='icon icon-sm rounded-circle alert-success'>
            <i className='text-success fas fa-truck-moving'></i>
          </span>
          <div className='text'>
            <h6 className='mb-1'>
              <strong>Información del Pedido</strong>
            </h6>
            <p className='m-0'>
              {preorder?.deliveryMethod === 'tienda'
                ? 'Recogida: '
                : 'Entregar: '}
              {preorder?.deliveryMethod
                ? preorder.deliveryMethod === 'domicilio'
                  ? 'Domicilio'
                  : 'Tienda'
                : 'Sin definir'}
            </p>
            <p>
              Pago:{' '}
              {preorder?.paymentMethod
                ? preorder.paymentMethod === 'paypal'
                  ? 'PayPal'
                  : preorder.paymentMethod === 'mano'
                  ? 'En mano'
                  : 'Tarjeta de Crédito'
                : 'Sin definir'}
            </p>
          </div>
        </article>
      </div>
      <div className='col-md-6 col-lg-4'>
        <article className='icontext align-items-start'>
          <span className='icon icon-sm rounded-circle alert-success'>
            <i className='text-success fas fa-map-marker-alt'></i>
          </span>
          <div className='text'>
            <h6 className='mb-1'>
              <strong>
                {preorder?.deliveryMethod
                  ? preorder?.deliveryMethod === 'tienda'
                    ? 'Recogid: '
                    : 'Entregar: '
                  : 'Sin definir'}
              </strong>
            </h6>
            <p>
              Dirección:{' '}
              {preorder?.deliveryMethod
                ? preorder?.deliveryMethod === 'tienda'
                  ? 'C. de Virgen de Lluc, 4, 28027, Madrid'
                  : `${preorder?.deliveryAddress?.address}, ${preorder?.deliveryAddress?.flat}, ${preorder?.deliveryAddress?.postalCode}, ${preorder?.deliveryAddress?.city}  `
                : 'Sin definir'}{' '}
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};

export default PreorderDetailInfo;
