import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createProduct } from '../../service/ProductsService';
import Toast from '../LoadingError/Toast';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import {
  adminSizes,
  adminColors,
  principalCategory,
  secondCategories,
} from '../../constants/constantsValues';

const AddProductMain = () => {
  const [name, setName] = useState();
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState();
  const [newImage, setNewImage] = useState();
  // const [countInStock, setCountInStock] = useState(0);
  const [stock, setStock] = useState();
  const [description, setDescription] = useState();
  const [category, setCategory] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();

  const { is775 } = useContext(ResponsiveContext);

  const handleCategory = (target) => {
    const newCategory = [...category];
    if (target.checked && !newCategory.includes(target.value)) {
      newCategory.push(target.value);
      setCategory(newCategory);
    }
    if (!target.checked && newCategory.includes(target.value)) {
      setCategory(newCategory.filter((size) => size !== target.value));
    }
  };

  const handleSubcategories = (target) => {
    const newSubcategories = [...subcategories];
    if (target.checked && !newSubcategories.includes(target.value)) {
      newSubcategories.push(target.value);
      setSubcategories(newSubcategories);
    }
    if (!target.checked && newSubcategories.includes(target.value)) {
      setSubcategories(
        newSubcategories.filter((size) => size !== target.value)
      );
    }
  };

  const handleSizes = (target) => {
    const newSizes = [...sizes];
    if (target.checked && !newSizes.includes(target.value)) {
      newSizes.push(target.value);
      setSizes(newSizes);
    }
    if (!target.checked && newSizes.includes(target.value)) {
      setSizes(newSizes.filter((size) => size !== target.value));
    }
  };

  const handleColors = (target) => {
    const newColors = [...colors];
    if (target.checked && !newColors.includes(target.value)) {
      newColors.push(target.value);
      setColors(newColors);
    }
    if (!target.checked && newColors.includes(target.value)) {
      setColors(newColors.filter((size) => size !== target.value));
    }
  };

  const handleStock = (target) => {
    target === 'Yes' ? setStock(true) : setStock(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (newImage) {
      const formData = new FormData();
      formData.append('file', newImage);

      formData.append('upload_preset', 'deportes-sporting');

      const data = await fetch(
        'https://api.cloudinary.com/v1_1/dlqllsdno/image/upload',
        { method: 'POST', body: formData }
      ).then((r) => r.json());

      setImage(data.secure_url);

      createProduct({
        name: name,
        price: price,
        image: data.secure_url,
        // countInStock: countInStock,
        stock: stock,
        description: description,
        category: category,
        subcategories: subcategories,
        sizes: sizes,
        colors: colors,
      })
        .then(() => {
          setLoading(false);
          navigate('/products');
        })
        .catch((error) => setError(error.response.data.message));
    } else {
      createProduct({
        name: name,
        price: price,
        image: image,
        // countInStock: countInStock,
        stock: stock,
        description: description,
        category: category,
        subcategories: subcategories,
        sizes: sizes,
        colors: colors,
      })
        .then(() => {
          setLoading(false);
          navigate('/products');
        })
        .catch((error) => setError(error.response.data.message));
    }
  };

  return (
    <>
      <Toast />
      <section className='content-main'>
        <form onSubmit={submitHandler}>
          {is775 ? (
            <div className='content-header'>
              <Link
                to='/products'
                className='btn btn-danger text-white'
                style={{ height: '100%' }}
              >
                Productos
              </Link>
              <h2 className='content-title'>Añadir producto</h2>
              <div>
                <button type='submit' className='btn btn-primary'>
                  Publicar
                </button>
              </div>
            </div>
          ) : (
            <>
              <div
                className='content-header
                  d-flex flex-row justify-content-between'
              >
                <Link to='/products' className='btn btn-danger text-white'>
                  Productos
                </Link>
                <button type='submit' className='btn btn-primary'>
                  Publicar
                </button>
              </div>
              <div className='content-header'>
                <h2
                  className='content-title mt-3'
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  Añadir producto
                </h2>
              </div>
            </>
          )}

          <div className='row mb-4'>
            <div className='col-12'>
              <div className='card mb-4 shadow-sm'>
                <div className='card-body'>
                  {error && <Message variant='alert-danger'>{error}</Message>}
                  {loading && <Loading />}
                  <div className='mb-4'>
                    <label htmlFor='product_title' className='form-label'>
                      Título del producto
                    </label>
                    <input
                      type='text'
                      placeholder='Escribir aquí'
                      className='form-control'
                      id='product_title'
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='product_price' className='form-label'>
                      Precio
                    </label>
                    <input
                      type='number'
                      placeholder='Escribir aquí'
                      className='form-control'
                      id='product_price'
                      required
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='product_price' className='form-label'>
                      Stock
                    </label>
                    <select
                      className='form-select form-select-lg mb-3 fs-6'
                      aria-label='.form-select-lg example'
                      onChange={(e) => handleStock(e.target.value)}
                    >
                      <option selected>
                        {stock === true ? 'Si' : stock === false ? 'No' : ''}
                      </option>
                      {stock === 'No' && <option value='Yes'>Si</option>}
                      {stock === 'Yes' && <option value='No'>No</option>}
                      {stock !== ('Yes' || 'No') && (
                        <>
                          <option value='Yes'>Si</option>
                          <option value='No'>No</option>
                        </>
                      )}
                    </select>
                    {/* <div className='mb-4'>
                    <label htmlFor='product_price' className='form-label'>
                      Count In Stock
                    </label>
                    <input
                      type='number'
                      placeholder='Escribir aquí'
                      className='form-control'
                      id='product_price'
                      required
                      value={countInStock}
                      onChange={(e) => setCountInStock(e.target.value)}
                    /> */}
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='product_price' className='form-label'>
                      Categoría Principal (Seleccionar 1)
                    </label>
                    <ul className='list-group'>
                      {principalCategory?.map((category) => (
                        <li className='list-group-item' key={category.name}>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            value={category.name}
                            onChange={(e) => handleCategory(e.target)}
                          />
                          <span className='ms-3'>{category.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='product_price' className='form-label'>
                      Subcategorías (Multi Select)
                    </label>
                    <ul className='list-group'>
                      {secondCategories?.map((subcategory) => (
                        <li className='list-group-item' key={subcategory.name}>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            value={subcategory.name}
                            onChange={(e) => handleSubcategories(e.target)}
                          />
                          <span className='ms-3'>{subcategory.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='product_price' className='form-label'>
                      Tallas
                    </label>
                    <ul className='list-group'>
                      {adminSizes.map((size) => (
                        <li className='list-group-item' key={size}>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            value={size}
                            onChange={(e) => handleSizes(e.target)}
                          />
                          <span className='ms-3'>{size}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='product_price' className='form-label'>
                      Colores
                    </label>
                    <ul className='list-group'>
                      {adminColors.map((color) => (
                        <li className='list-group-item' key={color.name}>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            value={color.name}
                            onChange={(e) => handleColors(e.target)}
                          />
                          <span className='ms-3'>{color.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='mb-4'>
                    <label className='form-label'>Descripción</label>
                    <textarea
                      placeholder='Escribir aquí'
                      className='form-control'
                      rows='7'
                      required
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className='mb-4'>
                    <label className='form-label'>Imágenes</label>
                    <input
                      className='form-control'
                      type='text'
                      value={image?.split('upload/')[1]}
                      onChange={(e) =>
                        setImage(
                          `https://res.cloudinary.com/dlqllsdno/image/upload/${e.target.value}`
                        )
                      }
                    />
                    <div className='mb-4'>
                      <label className='form-label'>Nueva Imagen</label>
                      <input
                        className='form-control'
                        name='image'
                        id='image'
                        type='file'
                        alt=''
                        onChange={(e) => setNewImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>{' '}
          </div>
        </form>
      </section>
    </>
  );
};

export default AddProductMain;
