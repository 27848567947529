import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

const Preorders = ({ preorders }) => {
  moment.locale('es');

  const handleTotal = (preorder) => {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    const itemsPrice = addDecimals(
      preorder.cartItems?.reduce(
        (acc, item) => acc + (item.price / 1.21) * item.qty,
        0
      )
    );
    const itemsTotalPrice = addDecimals(
      preorder.cartItems?.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
    const shippingPrice = preorder.deliveryMethod
      ? preorder.deliveryMethod === 'tienda'
        ? 0
        : addDecimals(itemsPrice > 200 / 1.21 ? 0 : 5.95 / 1.21)
      : 0;
    const taxPrice = addDecimals(
      Number((0.21 * itemsPrice + 0.21 * shippingPrice).toFixed(2))
    );
    const totalPrice =
      preorder.deliveryMethod === 'domicilio'
        ? itemsPrice > 200 / 1.21
          ? Number(itemsTotalPrice).toFixed(2)
          : (Number(itemsTotalPrice) + Number(5.95)).toFixed(2)
        : Number(itemsTotalPrice).toFixed(2);

    preorder.itemsPrice = itemsPrice;
    preorder.shippingPrice = shippingPrice;
    preorder.taxPrice = taxPrice;
    preorder.totalPrice = totalPrice;
  };

  return (
    <table className='table'>
      <thead>
        <tr>
          <th scope='col'>Nombre</th>
          <th scope='col'>Email</th>
          <th scope='col'>Total</th>
          <th scope='col'>Fecha</th>
          <th scope='col' className='text-end'>
            Acción
          </th>
        </tr>
      </thead>
      <tbody>
        {preorders?.map((preorder) => (
          <tr key={preorder._id}>
            <td>
              <b>{preorder.user.name}</b>
            </td>
            <td>{preorder.user.email}</td>
            <td>
              {handleTotal(preorder)}
              {preorder?.totalPrice.toString().replace('.', ',')}
              {preorder?.totalPrice.toString().replace('.', ',').split(',')[1]
                ? preorder?.totalPrice
                    .toString()
                    .replace('.', ',')
                    .split(',')[1].length === 1
                  ? '0'
                  : ''
                : ',00'}{' '}
              €
            </td>
            <td>{moment(preorder.createdAt).format('MMM Do YY')}</td>
            <td className='d-flex justify-content-end align-item-center'>
              <Link to={`/preorder/${preorder._id}`} className='text-success'>
                <i className='fas fa-eye'></i>
              </Link>
            </td>
          </tr>
        ))}

        {/* Not paid Not delivered */}
        {/* <tr>
          <td>
            <b>Velcro Sneakers For Boys & Girls (Blue)</b>
          </td>
          <td>user@example.com</td>
          <td>$45,789</td>
          <td>
            <span className="badge rounded-pill alert-danger">Not paid</span>
          </td>
          <td>Dec 12 2021</td>
          <td>
            <span className="badge btn-dark">Not Delivered</span>
          </td>
          <td className="d-flex justify-content-end align-item-center">
            <Link to={`/preorder`} className="text-success">
              <i className="fas fa-eye"></i>
            </Link>
          </td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default Preorders;
