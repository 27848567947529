import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const TopTotal = ({ orders, products }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (orders) {
      let totalSale = 0;
      orders.map((order) =>
        order.isPaid === true
          ? (totalSale = totalSale + order.totalPrice)
          : null
      );
      setTotal(totalSale);
    }
  }, [orders]);

  // TODO
  // Charts

  return (
    <div className='row'>
      <div className='col-lg-4'>
        <div className='card card-body mb-4 shadow-sm'>
          <article className='icontext'>
            <span className='icon icon-sm rounded-circle alert-primary'>
              <i className='text-primary fas fa-usd-circle'></i>
            </span>
            <div className='text'>
              <h6 className='mb-1'>Ventas Totales</h6>{' '}
              <span>{total.toFixed(0)} €</span>
            </div>
          </article>
        </div>
      </div>
      <div className='col-lg-4'>
        <div className='card card-body mb-4 shadow-sm'>
          <article className='icontext'>
            <span className='icon icon-sm rounded-circle alert-success'>
              <i className='text-success fas fa-bags-shopping'></i>
            </span>
            <div className='text'>
              <h6 className='mb-1'>Pedidos Totales</h6>
              {orders ? <span>{orders.length}</span> : <span>0</span>}
            </div>
          </article>
        </div>
      </div>
      <div className='col-lg-4'>
        <div className='card card-body mb-4 shadow-sm'>
          <article className='icontext'>
            <span className='icon icon-sm rounded-circle alert-warning'>
              <i className='text-warning fas fa-shopping-basket'></i>
            </span>
            <div className='text'>
              <h6 className='mb-1'>Productos Totales</h6>
              {products ? <span>{products.length}</span> : <span>0</span>}
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default TopTotal;
