import { create } from './BaseService';

const http = create();

// Order

export function createOrder(order) {
  return http.post('/new-order', { order });
}

export function getOrders() {
  return http.get('/my-orders');
}

export function getAllOrders() {
  return http.get('/orders');
}

export function getAllPreorders() {
  return http.get('/preorders');
}

export function findOrder(id) {
  return http.get(`/orders/${id}`);
}

export function updateOrder(payment) {
  return http.post('/update-order/', { payment });
}

export function updateOrderStatus(id, status) {
  return http.post('/order-status/', { id, status });
}

export function updatePreorderStatus(id, status) {
  return http.post('/preorder-status/', { id, status });
}

export function deleteOrder(id) {
  return http.post('/delete-order', { id });
}

// Preorder

export function createPreorder(cartItems) {
  return http.post('/new-preorder/', { cartItems });
}

export function getPreorders() {
  return http.get('/my-preorders/');
}

export function findPreorder(preorderId) {
  return http.get(`/preorder/${preorderId}`);
}

export function updatePreorder(data) {
  return http.post('/update-preorder/', { data });
}

export function deletePreorder(id) {
  return http.post('/delete-preorder', { id });
}
