import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteProduct } from '../../service/ProductsService';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';

const Product = ({ product }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const deletehandler = (id) => {
    if (window.confirm('¿Estás seguro/a?')) {
      setLoading(true);
      deleteProduct(id)
        .then(() => {
          setLoading(false);
          window.location.reload();
        })
        .catch((error) => setError(error.response.data.message));
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant='alert-danger'>{error}</Message>
      ) : (
        <div className='col-md-6 col-sm-6 col-lg-3 mb-5'>
          <div className='card card-product-grid shadow-sm'>
            <Link to='#' className='img-wrap'>
              <img src={product.image} alt='Product' />
            </Link>
            <div className='info-wrap'>
              <Link to='#' className='title text-truncate'>
                {product.name}
              </Link>
              <div className='price mb-2'>
                {product?.price.toString().replace('.', ',')}
                {product?.price.toString().replace('.', ',').split(',')[1]
                  ? product?.price.toString().replace('.', ',').split(',')[1]
                      .length === 1
                    ? '0'
                    : ''
                  : ',00'}{' '}
                €
              </div>
              <div className='row'>
                <Link
                  to={`/product/${product._id}/edit`}
                  className='btn btn-sm btn-outline-success p-2 pb-3 col-md-6'
                >
                  <i className='fas fa-pen'></i>
                </Link>
                <Link
                  to='#'
                  onClick={() => deletehandler(product._id)}
                  className='btn btn-sm btn-outline-danger p-2 pb-3 col-md-6'
                >
                  <i className='fas fa-trash-alt'></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
