import React, { useState, useEffect } from 'react';
import Toast from '../LoadingError/Toast';
import { Link, useNavigate } from 'react-router-dom';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';
import { findProduct, updateProduct } from '../../service/ProductsService';
import { useContext } from 'react';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import {
  adminSizes,
  adminColors,
  principalCategory,
  secondCategories,
} from '../../constants/constantsValues';

const EditProductMain = ({ productId }) => {
  const [product, setProduct] = useState();
  const [name, setName] = useState();
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState();
  const [newImage, setNewImage] = useState();
  const [stock, setStock] = useState();
  // const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const navigate = useNavigate();

  const { is775 } = useContext(ResponsiveContext);

  useEffect(() => {
    if (productId) {
      findProduct(productId.id)
        .then((product) => {
          setProduct(product);
          setLoading(false);
        })
        .catch((error) => setError(error.response?.data.message));
    }
  }, [productId]);

  useEffect(() => {
    if (product) {
      setName(product.name);
      setPrice(product.price);
      setImage(product.image);
      setStock(product.stock);
      // setCountInStock(product.countInStock);
      setDescription(product.description);
      setCategories(product.category);
      setSubcategories(product.subcategories);
      setSizes(product.sizes);
      setColors(product.colors);
    }
  }, [product]);

  const handleCategory = (target) => {
    const newCategory = [...categories];
    if (target.checked && !newCategory.includes(target.value)) {
      newCategory.push(target.value);
      setCategories(newCategory);
    }
    if (!target.checked && newCategory.includes(target.value)) {
      setCategories(newCategory.filter((size) => size !== target.value));
    }
  };

  const handleSubcategories = (target) => {
    const newSubcategories = [...subcategories];
    if (target.checked && !newSubcategories.includes(target.value)) {
      newSubcategories.push(target.value);
      setSubcategories(newSubcategories);
    }
    if (!target.checked && newSubcategories.includes(target.value)) {
      setSubcategories(
        newSubcategories.filter((size) => size !== target.value)
      );
    }
  };

  const handleSizes = (target) => {
    const newSizes = [...sizes];
    if (target.checked && !newSizes.includes(target.value)) {
      newSizes.push(target.value);
      setSizes(newSizes);
    }
    if (!target.checked && newSizes.includes(target.value)) {
      setSizes(newSizes.filter((size) => size !== target.value));
    }
  };

  const handleColors = (target) => {
    const newColors = [...colors];
    if (target.checked && !newColors.includes(target.value)) {
      newColors.push(target.value);
      setColors(newColors);
    }
    if (!target.checked && newColors.includes(target.value)) {
      setColors(newColors.filter((size) => size !== target.value));
    }
  };

  const handleStock = (target) => {
    target === 'Yes' ? setStock(true) : setStock(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (newImage) {
      const formData = new FormData();
      formData.append('file', newImage);

      formData.append('upload_preset', 'deportes-sporting');

      const data = await fetch(
        'https://api.cloudinary.com/v1_1/dlqllsdno/image/upload',
        { method: 'POST', body: formData }
      ).then((r) => r.json());

      setImage(data.secure_url);

      const productUpdated = {
        name: name,
        price: price,
        image: data.secure_url,
        stock: stock,
        description: description,
        category: categories,
        subcategories: subcategories,
        sizes: sizes,
        colors: colors,
      };

      updateProduct(product._id, productUpdated)
        .then(() => {
          setLoading(false);
          navigate('/products');
        })
        .catch((error) => setError(error.response.data.message));
    } else {
      const productUpdated = {
        name: name,
        price: price,
        image: image,
        stock: stock,
        description: description,
        category: categories,
        subcategories: subcategories,
        sizes: sizes,
        colors: colors,
      };

      updateProduct(product._id, productUpdated)
        .then(() => {
          setLoading(false);
          navigate('/products');
        })
        .catch((error) => setError(error.response.data.message));
    }
  };

  // TODO
  // splice url cloudinary

  return (
    <>
      <Toast />
      <section className='content-main' style={{ maxWidth: '1200px' }}>
        <form onSubmit={submitHandler}>
          {is775 ? (
            <div className='content-header'>
              <Link
                to='/products'
                className='btn btn-danger text-white'
                style={{ height: '100%' }}
              >
                Productos
              </Link>

              <h2 className='content-title'>Actualizar Producto</h2>
              <div>
                <button type='submit' className='btn btn-primary'>
                  Publicar
                </button>
              </div>
            </div>
          ) : (
            <>
              <div
                className='content-header
                  d-flex flex-row justify-content-between'
              >
                <Link to='/products' className='btn btn-danger text-white'>
                  Productos
                </Link>
                <button type='submit' className='btn btn-primary'>
                  Publicar
                </button>
              </div>
              <div className='content-header'>
                <h2
                  className='content-title mt-3'
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  Actualizar Producto
                </h2>
              </div>
            </>
          )}

          <div className='row mb-4'>
            <div className='col-xl-8 col-lg-8'>
              <div className='card mb-4 shadow-sm'>
                <div className='card-body'>
                  {error && <Message variant='alert-danger'>{error}</Message>}
                  {loading && <Loading />}
                  {loading ? (
                    <Loading />
                  ) : error ? (
                    <Message variant='alert-danger'>{error}</Message>
                  ) : (
                    <>
                      <div className='mb-4'>
                        <label htmlFor='product_title' className='form-label'>
                          Título del Producto
                        </label>
                        <input
                          type='text'
                          placeholder='Escribir aquí'
                          className='form-control'
                          id='product_title'
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='product_price' className='form-label'>
                          Precio
                        </label>
                        <input
                          type='number'
                          placeholder='Escribir aquí'
                          className='form-control'
                          id='product_price'
                          required
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='product_price' className='form-label'>
                          Stock
                        </label>
                        <select
                          className='form-select form-select-lg mb-3 fs-6'
                          aria-label='.form-select-lg example'
                          onChange={(e) => handleStock(e.target.value)}
                        >
                          <option selected>
                            {product.stock === true
                              ? 'Si'
                              : product.stock === false
                              ? 'No'
                              : ''}
                          </option>
                          {product.stock === false && (
                            <option value='Yes'>Si</option>
                          )}
                          {product.stock === true && (
                            <option value='No'>No</option>
                          )}
                          {product.stock !== (true || false) && (
                            <>
                              <option value='Yes'>Si</option>
                              <option value='No'>No</option>
                            </>
                          )}
                        </select>
                        {/* <input
                          type='number'
                          placeholder='Escribir aquí'
                          className='form-control'
                          id='product_price'
                          required
                          value={countInStock}
                          onChange={(e) => setCountInStock(e.target.value)}
                        /> */}
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='product_price' className='form-label'>
                          Categoría Principal (Seleccionar 1)
                        </label>
                        <ul className='list-group'>
                          {principalCategory?.map((category) => (
                            <li className='list-group-item' key={category.name}>
                              <input
                                className='form-check-input me-1'
                                type='checkbox'
                                value={category.name}
                                checked={categories.includes(category.name)}
                                onChange={(e) => handleCategory(e.target)}
                              />
                              <span className='ms-3'>{category.name}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='product_price' className='form-label'>
                          Subcategorías (Multi Select)
                        </label>
                        <ul className='list-group'>
                          {secondCategories?.map((subcategory) => (
                            <li
                              className='list-group-item'
                              key={subcategory.name}
                            >
                              <input
                                className='form-check-input me-1'
                                type='checkbox'
                                value={subcategory.name}
                                checked={subcategories.includes(
                                  subcategory.name
                                )}
                                onChange={(e) => handleSubcategories(e.target)}
                              />
                              <span className='ms-3'>{subcategory.name}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='product_price' className='form-label'>
                          Tallas
                        </label>
                        <ul className='list-group'>
                          {adminSizes.map((size) => (
                            <li className='list-group-item' key={size}>
                              <input
                                className='form-check-input me-1'
                                type='checkbox'
                                value={size}
                                checked={sizes.includes(size)}
                                onChange={(e) => handleSizes(e.target)}
                              />
                              <span className='ms-3'>{size}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='product_price' className='form-label'>
                          Colores
                        </label>
                        <ul className='list-group'>
                          {adminColors.map((color) => (
                            <li className='list-group-item' key={color.name}>
                              <input
                                className='form-check-input me-1'
                                type='checkbox'
                                value={color.name}
                                checked={colors.includes(color.name)}
                                onChange={(e) => handleColors(e.target)}
                              />
                              <span className='ms-3'>{color.name}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {/* <div className='mb-4'>
                        <label htmlFor='product_price' className='form-label'>
                          Count In Stock
                        </label>
                        <button type='submit' className='btn btn-secondary'>
                          Add stock
                        </button>
                        <select
                          className='form-select form-select-lg mb-3'
                          aria-label='.form-select-lg example'
                        >
                          {sizes.length > 0 &&
                            sizes.map((size) => (
                              <option selected>{size}</option>
                            ))}
                          <option selected>Open this select menu</option>
                          <option value='1'>One</option>
                          <option value='2'>Two</option>
                          <option value='3'>Three</option>
                        </select>
                        <select
                          className='form-select form-select-lg mb-3'
                          aria-label='.form-select-lg example'
                        >
                          {colors.length > 0 &&
                            colors.map((size) => (
                              <option selected>{size}</option>
                            ))}
                          <option selected>Open this select menu</option>
                          <option value='1'>One</option>
                          <option value='2'>Two</option>
                          <option value='3'>Three</option>
                        </select>
                        <input
                          type='number'
                          placeholder='Escribir aquí'
                          className='form-control'
                          id='product_price'
                          required
                          value={countInStock}
                          onChange={(e) => setCountInStock(e.target.value)}
                        />
                      </div> */}
                      <div className='mb-4'>
                        <label className='form-label'>Descripción</label>
                        <textarea
                          placeholder='Escribir aquí'
                          className='form-control'
                          rows='7'
                          required
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                      <div className='mb-4'>
                        <label className='form-label'>Imagen Actual</label>
                        <input
                          className='form-control'
                          type='text'
                          value={image?.split('upload/')[1]}
                          onChange={(e) =>
                            setImage(
                              `https://res.cloudinary.com/dlqllsdno/image/upload/${e.target.value}`
                            )
                          }
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='form-label'>Nueva Imagen</label>
                        <input
                          className='form-control'
                          name='image'
                          id='image'
                          type='file'
                          alt=''
                          onChange={(e) => setNewImage(e.target.files[0])}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default EditProductMain;
