import { create } from './BaseService';

const http = create(false);

export const login = (credentials) => {
  return http.post('/login', credentials);
};

export const adminLogin = (credentials) => {
  return http.post('/admin-login', credentials);
};

export const loginGoogle = (email, password) => {
  return http.post('/login-google', { email, password });
};
