import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Header from '../components/Header';
import AddSectionMain from '../components/Home/AddSectionMain';

const AddSectionHome = ({ user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || (user && !user.isAdmin)) {
      navigate('/login');
    }
  }, [user]);

  return (
    <>
      <Sidebar />
      <main className='main-wrap'>
        <Header />
        <AddSectionMain />
      </main>
    </>
  );
};

export default AddSectionHome;
