export const adminSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '106',
  '112',
  '1',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '22',
  'U',
];

export const adminColors = [
  {
    color: '#49b675',
    name: 'Verde',
  },
  {
    color: '#0000cd',
    name: 'Azul',
  },
  {
    color: '#00446a',
    name: 'Azul Marino',
  },
  {
    color: '#ffffff',
    name: 'Blanco',
  },
  {
    color: '#d3d3d1',
    name: 'Gris',
  },
  {
    color: '#e71837',
    name: 'Rojo',
  },
  {
    color: '#641c34',
    name: 'Burdeos',
  },
  {
    color: '#000000',
    name: 'Negro',
  },
];

export const principalCategory = [
  {
    name: 'Brains Internacional',
    url: 'https://res.cloudinary.com/dlqllsdno/image/upload/v1657963105/deportes-sporting/Brains-Logo-Escudo_vxgkne.png',
    value: 'brainsInternacional',
  },
  {
    name: 'Brains Nursery School',
    url: 'https://res.cloudinary.com/dlqllsdno/image/upload/v1657963106/deportes-sporting/Brains-Nursery-logo-escuelas-infantiles_hcx2il.png',
    value: 'brainsNurserySchool',
  },
];

export const secondCategories = [
  {
    name: 'Uniformes',
    value: 'uniforms',
  },
];
