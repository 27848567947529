import React from 'react';

const OrderDetailInfo = ({ order }) => {
  return (
    <div className='row mb-5 order-info-wrap'>
      <div className='col-md-6 col-lg-4'>
        <article className='icontext align-items-start'>
          <span className='icon icon-sm rounded-circle alert-success'>
            <i className='text-success fas fa-user'></i>
          </span>
          <div className='text'>
            <h6 className='mb-1'>
              <strong>Cliente</strong>
            </h6>
            <p className='mb-1'>
              {order.user.name} <br />
              <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
            </p>
            <p className='mb-1'>{order.user.phone}</p>
          </div>
        </article>
      </div>
      <div className='col-md-6 col-lg-4'>
        <article className='icontext align-items-start'>
          <span className='icon icon-sm rounded-circle alert-success'>
            <i className='text-success fas fa-truck-moving'></i>
          </span>
          <div className='text'>
            <h6 className='mb-1'>
              <strong>Información del Pedido</strong>
            </h6>
            <p className='m-0'>
              {order?.deliveryMethod === 'tienda'
                ? 'Recogida en tienda'
                : 'Envío a domicilio'}{' '}
            </p>
            <p>
              {order?.paymentMethod === 'mano'
                ? 'Pago en tienda'
                : order?.paymentMethod === 'paypal'
                ? `Pago mediante ${order?.paymentMethod}`
                : ''}
            </p>
          </div>
        </article>
      </div>
      <div className='col-md-6 col-lg-4'>
        <article className='icontext align-items-start'>
          <span className='icon icon-sm rounded-circle alert-success'>
            <i className='text-success fas fa-map-marker-alt'></i>
          </span>
          <div className='text'>
            <h6 className='mb-1'>
              <strong>
                {order?.deliveryMethod === 'tienda'
                  ? 'Recogida: '
                  : 'Entregar: '}
              </strong>
            </h6>
            <p>
              Dirección:{' '}
              {order?.deliveryMethod
                ? order?.deliveryMethod === 'tienda'
                  ? 'C. de Virgen de Lluc, 4, 28027, Madrid'
                  : `${order?.deliveryAddress?.address}, ${order?.deliveryAddress?.flat}, ${order?.deliveryAddress?.postalCode}, ${order?.deliveryAddress?.city}  `
                : 'Sin definir'}{' '}
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};

export default OrderDetailInfo;
