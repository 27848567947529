import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Header from '../components/Header';
import EditProductMain from '../components/products/EditproductMain';

const ProductEditScreen = ({ user }) => {
  const navigate = useNavigate();
  const productId = useParams();

  useEffect(() => {
    if (!user || (user && !user.isAdmin)) {
      navigate('/login');
    }
  });

  return (
    <>
      <Sidebar />
      <main className='main-wrap'>
        <Header />
        <EditProductMain productId={productId} />
      </main>
    </>
  );
};
export default ProductEditScreen;
