import moment from 'moment';
import 'moment/locale/es';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';

const LatestOrder = ({ loading, error, orders }) => {
  const [inverseOrders, setInverseOrders] = useState([]);

  moment.locale('es');

  useEffect(() => {
    if (orders) {
      const inverseArray = [];
      orders.map((order) => inverseArray.unshift(order));
      setInverseOrders(inverseArray);
    }
  }, [orders]);

  return (
    <div className='card-body'>
      <h4 className='card-title mb-4'>Nuevos pedidos</h4>
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant='alert-danger'>{error}</Message>
      ) : (
        <div className='table-responsive'>
          <table className='table'>
            <tbody>
              {inverseOrders.length > 0 &&
                inverseOrders.slice(0, 10).map((order) => (
                  <tr key={order._id}>
                    <td>
                      <b>{order.user.name}</b>
                    </td>
                    <td>{order.user.email}</td>
                    <td>
                      {order?.totalPrice.toString().replace('.', ',')}
                      {order?.totalPrice
                        .toString()
                        .replace('.', ',')
                        .split(',')[1]
                        ? order?.totalPrice
                            .toString()
                            .replace('.', ',')
                            .split(',')[1].length === 1
                          ? '0'
                          : ''
                        : ',00'}{' '}
                      €
                    </td>
                    <td>
                      {order.isPaid ? (
                        <span className='badge rounded-pill alert-success'>
                          Pagado el {moment(order.paidAt).format('MMM Do YY')}
                        </span>
                      ) : (
                        <span className='badge rounded-pill alert-danger'>
                          No pagado
                        </span>
                      )}
                    </td>
                    <td>{moment(order.createdAt).calendar()}</td>
                    <td className='d-flex justify-content-end align-item-center'>
                      <Link to={`/order/${order._id}`} className='text-success'>
                        <i className='fas fa-eye'></i>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default LatestOrder;
