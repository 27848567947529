import React, { createContext } from 'react';
import { useMediaQuery } from 'react-responsive';

export const ResponsiveContext = createContext();

const ResponsiveContextProvider = ({ children }) => {
  const is1900 = useMediaQuery({ query: '(min-width: 1900px)' });
  const is1824 = useMediaQuery({ query: '(min-width: 1824px)' });
  const is1700 = useMediaQuery({ query: '(min-width: 1700px)' });
  const is1600 = useMediaQuery({ query: '(min-width: 1600px)' });
  const is1500 = useMediaQuery({ query: '(min-width: 1500px)' });
  const is1400 = useMediaQuery({ query: '(min-width: 1400px)' });
  const is1300 = useMediaQuery({ query: '(min-width: 1300px)' });
  const is1200 = useMediaQuery({ query: '(min-width: 1200px)' });
  const is1100 = useMediaQuery({ query: '(min-width: 1100px)' });
  const is1000 = useMediaQuery({ query: '(min-width: 1000px)' });
  const is900 = useMediaQuery({ query: '(min-width: 900px)' });
  const is800 = useMediaQuery({ query: '(min-width: 800px)' });
  const is775 = useMediaQuery({ query: '(min-width: 775px)' });
  const is750 = useMediaQuery({ query: '(min-width: 750px)' });
  const is700 = useMediaQuery({ query: '(min-width: 700px)' });
  const is650 = useMediaQuery({ query: '(min-width: 650px)' });
  const is600 = useMediaQuery({ query: '(min-width: 600px)' });
  const is575 = useMediaQuery({ query: '(min-width: 575px)' });
  const is550 = useMediaQuery({ query: '(min-width: 550px)' });
  const is500 = useMediaQuery({ query: '(min-width: 500px)' });
  const is450 = useMediaQuery({ query: '(min-width: 450px)' });
  const is350 = useMediaQuery({ query: '(min-width: 350px)' });

  return (
    <ResponsiveContext.Provider
      value={{
        is1900,
        is1824,
        is1700,
        is1600,
        is1500,
        is1400,
        is1300,
        is1200,
        is1100,
        is1000,
        is900,
        is800,
        is775,
        is750,
        is700,
        is650,
        is600,
        is575,
        is550,
        is500,
        is450,
        is350,
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};

export default ResponsiveContextProvider;
