import React, { useEffect, useState } from 'react';
import PreorderDetailProducts from './PreorderDetailProducts';
import PreorderDetailInfo from './PreorderDetailInfo';
import { Link } from 'react-router-dom';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';
import moment from 'moment';
import 'moment/locale/es';
import { findPreorder } from '../../service/OrdersService';

const PreorderDetailmain = ({ preorderId }) => {
  const [preorder, setPreorder] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  moment.locale('es');

  useEffect(() => {
    findPreorder(preorderId)
      .then((preorder) => {
        setPreorder(preorder[0]);
        setLoading(false);
      })
      .catch((e) => setError(e));
  }, []);

  return (
    <section className='content-main'>
      <div className='content-header'>
        <Link to='/preorders' className='btn btn-dark text-white'>
          Volver a los pedidos sin completar
        </Link>
      </div>

      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant='alert-danger'>{error}</Message>
      ) : (
        <div className='card'>
          <header className='card-header p-3 Header-green'>
            <div className='row align-items-center '>
              <div className='col-12'>
                <span>
                  <i className='far fa-calendar-alt mx-2'></i>
                  <b className='text-white'>
                    {moment(preorder.createdAt).format('llll')}
                  </b>
                </span>
                <br />
                <small className='text-white mx-3 '>
                  ID del Pedido sin confirmar: {preorder._id}
                </small>
              </div>
              {/* <div className='col-lg-6 col-md-6 ms-auto d-flex justify-content-end align-items-center'> */}
              {/* <select
                  className='form-select d-inline-block'
                  style={{ maxWidth: '300px' }}
                  onChange={(e) => handleStatus(e.target.value)}
                >
                  <option
                    selected={preorder.orderStatus === 'awaitingPayment'}
                    value='awaitingPayment'
                  >
                    Esperando el pago
                  </option>
                  <option
                    selected={preorder.orderStatus === 'paymentConfirmed'}
                    value='paymentConfirmed'
                  >
                    Pago confirmado
                  </option>
                  <option
                    selected={preorder.orderStatus === 'shipped'}
                    value='shipped'
                  >
                    Enviado
                  </option>
                  <option
                    selected={preorder.orderStatus === 'delivered'}
                    value='delivered'
                  >
                    Entregado
                  </option>
                </select> */}
              {/* <Link className='btn btn-success ms-2' to='#'>
                  <i className='fas fa-print'></i>
                </Link> */}
              {/* </div> */}
            </div>
          </header>
          <div className='card-body'>
            {/* Order info */}
            <PreorderDetailInfo preorder={preorder} />

            <div className='row'>
              <div className='col-12'>
                <div className='table-responsive'>
                  <PreorderDetailProducts
                    preorder={preorder}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PreorderDetailmain;
